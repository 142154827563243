















































import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MessageBoxListView from '@/views/chat/MessageBoxListView.vue';
import useTestDataAttribute from '@/utils/TestDataAttribute';
import GroupType from '@/utils/enums/chat/GroupType';
import ChatModuleHelper from '@/components/chat/ChatModuleHelper.vue';
import Exhibitor from '@/models/graphql/Exhibitor';
import { namespace } from 'vuex-class';

const exhibitorStore = namespace('ExhibitorStore');

/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  methods: { useTestDataAttribute },
  components: {
    MessageBoxListView,
    ButtonComponent,
  },
})
export default class EpMessageDashboardWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
  ChatModuleHelper,
) {
  @exhibitorStore.Getter('fetchAdminPanelExhibitor')
  private adminPanelExhibitor!: Exhibitor;

  private basicFilter: object | null = null;

  private get chatReps(): string[] {
    if (this.adminPanelExhibitor) {
      // eslint-disable-next-line no-underscore-dangle
      return (this.adminPanelExhibitor._chatReps || []).map((rep) => rep.user?.uid || '');
    }
    return [];
  }

  created(): void {
    this.storeContext = 'ExhibitorPortalMessageDashboardWidgetStore';
    this.setDataConfig();
    this.initComponentConfig();
  }

  private goToMessages(): void {
    this.$router.push({
      name: 'exhibitor-portal-message',
    });
  }

  @Watch('isReadyToDisplay', { immediate: true })
  private initComponentConfig(): void {
    const { companyId } = this.$route.params;
    if (companyId && this.isReadyToDisplay) {
      this.basicFilter = {
        type_not: GroupType.QNA,
        users: { uid: this.authUser.uid },
        target: {
          uid: companyId,
        },
      };
    }
  }
}
